import React, { useRef } from 'react';
import styles from './WholeHomeWifi.module.scss';
import Button from '~/components/shared/Button';
import useIntersectionObserver from '~/helpers/hooks/useIntersectionObserver';
import { fireElementViewedEvent } from '~/helpers/utils/fireTagular';
import Typography from '~/components/shared/Typography';

const BASE_DATA = {
  epqCopy: 'SHOP NEW PLANS',
  headerCopy: 'Say goodbye to Wi-Fi dead zones',
  headerFontColor: '#0096fa',
  image: 'satellite-worker.jpg',
  subheadCopy:
    'Wi-Fi 6 delivers improved speed with enhanced security and better performance for multiple devices. Hughesnet also offers Whole Home Wi-Fi. Get wall-to-wall Wi-Fi coverage with optional Mesh Wi-Fi Nodes.',
  subheadFontColor: '#424242',
  imagePositionLeft: false,
};

export default function WholeWifiModule(metadata) {
  const {
    headerFontColor,
    headerCopy,
    subheadCopy,
    subheadFontColor,
    epqCopy,
    image,
    imagePositionLeft,
  } = { ...BASE_DATA, ...metadata };

  const wifiModule = useRef();

  useIntersectionObserver(
    [wifiModule],
    (el) =>
      fireElementViewedEvent(
        'Home Wifi Section',
        'Home Wifi Section',
        'Home Wifi Section',
        headerCopy
      ),
    undefined,
    // Fire tagular only when the full element is visible
    { threshold: 1.0 }
  );

  const imgSrc = `/images/section/${image}`;

  return (
    <section className={styles.column} ref={wifiModule}>
      <div
        className={`${styles.container} ${
          imagePositionLeft && styles.reverseOrder
        }`}
        id="extend"
      >
        <div
          className={`${styles.textContent} ${
            imagePositionLeft && styles.reverseContent
          }`}
        >
          <Typography variant="h3" className={styles.headline}>
            <span style={{ color: headerFontColor }}>{headerCopy}</span>
          </Typography>
          <Typography variant="p" className={styles.subheadline}>
            <span style={{ color: subheadFontColor }}>{subheadCopy}</span>
          </Typography>
          <Button
            href="/order-online"
            tracking={{
              position: 'WHOLE HOME WIFI',
              location: 'WHOLE HOME WIFI',
              elementType: 'BUTTON',
              text: epqCopy,
              actionOutcome: 'Redirect to Cart',
            }}
            fuse
            className={styles.cta}
          >
            {epqCopy}
          </Button>
        </div>
        <div className={styles.imgWrapper}>
          <picture>
            <img
              src={imgSrc}
              alt="Man smiling at laptop"
              className={styles.image}
            />
          </picture>
        </div>
      </div>
    </section>
  );
}
